<template>
    <div class="filter" v-if="currFilter">
        <filter-item
            :sid="sid"
            :value="currFilter.type"
            :match-range="currFilter.matchRange"
            :items="filterItems"
            @change="handleChange"
        ></filter-item>

        <filter-condition
            v-if="visibleStates.filterCondition"
            :value="currFilter.condition"
            :options="options.filterCondition"
            :currFilter="currFilter"
            :filter-item="states.filterItem"
            :filter-value="currFilter.value"
            :filter-value-options="options.filterValue"
            :cType="filterValueType"
            :step-span="stepSpan"
            @change="handleChange"
        >
            <filter-sub-type
                v-if="currFilter.subType"
                slot="filterSubType"
                :value="currFilter.subType"
                :items="options.filterSubType"
                @change="handleChange"
            >
            </filter-sub-type>
            <filter-value
                slot="filterValue"
                v-if="visibleStates.filterValue"
                :sid="sid"
                :cType="filterValueType"
                :size="filterValueSize"
                :timeZone="timeZone"
                :currFilter="currFilter"
                :value="currFilter.value"
                :options="options.filterValue"
                :options-loading="fetchStates.filterValue.loading"
                :filter-item="states.filterItem"
                :filter-condition="states.filterCondition"
                :expandAfterInit="true"
                @change="handleChange"
            ></filter-value>
        </filter-condition>

        <behavioral-condition
            v-if="visibleStates.behavioralCondition"
            :value="currFilter.timesOccured"
            :options="options.behavioralCondition"
            :sid="sid"
            :filter-item="states.filterItem"
            :filter-behavioral-times="filterBehavioralTimes"
            :show-times="Boolean(canChoiceTimes && visibleStates.behavioralTimes)"
            @change="handleChange"
        >
            <behavioral-times
                slot="behavioralTimes"
                v-if="canChoiceTimes && visibleStates.behavioralTimes"
                :sid="sid"
                :value="filterBehavioralTimes"
                :options="options.behavioralTimes"
                :filter-item="states.filterItem"
                @change="handleChange"
            ></behavioral-times>
        </behavioral-condition>

        <behavioral-time
            v-if="visibleStates.behavioralTime"
            :value="currFilter"
            :sid="sid"
            :filter-item="states.filterItem"
            @change="handleChange"
        ></behavioral-time>

        <div class="button-remove" @click="handleDelete">
            <pt-icon
                icon="pt-icon--close"
                :icon-style="{
                    width: '16px',
                    height: '16px'
                }"
            ></pt-icon>
        </div>
    </div>
</template>

<script>
import dayjs from 'dayjs';
import axios from 'axios';
import merge from 'lodash/merge';
import usersApis from '@/apis/users.apis';
import uuidUtil from '@/common/utils/uuid.utils';
import cloneUtils from '@/common/utils/clone.utils';
import commonUtils from '@/common/utils/common.utils';
import permissionUtils from '@/common/utils/permission';
import { searchTree, getNextStepForCondition } from '../../../usergrp-util';
import audienceConfig from '../../../usergrp-config';
import settingConfig from '@/components/campaign/setting/setting.config';
import FilterItem from './FilterItem';
import FilterSubType from './FilterSubType.vue';
import FilterCondition from './FilterCondition';
import FilterValue from './FilterValue.vue';
import BehavioralCondition from './BehavioralCondition';
import BehavioralTimes from './BehavioralTimes';
import BehavioralTime from './BehavioralTime';
import PermissionConfig from '@/common/configs/permissions.config';
export default {
    name: 'FilterContent',

    props: {
        sid: String,
        timeZone: String,
        filter: Object,
        filterLen: Number,
        filterItems: Array,
        valueOptions: Object
    },

    data() {
        // 当前组件状态值
        const states = {
            filterItem: null, //过滤项
            filterSubType: null,
            filterCondition: null, //过滤条件
            filterValue: null, //过滤值
            behavioralCondition: null, //行为条件
            behavioralTimes: null, //行为次数
            behavioralTime: null //条件时间
        };
        // 当前过滤项下会展示的组件列表及显示状态
        const visibleStates = {
            // filterItem: true,
            // filterCondition: false,
            // filterValue: false,
            // behavioralCondition: false,
            // behavioralTimes: false,
            // behavioralTime: false
        };
        // 当前组件选项值
        const options = {
            filterItem: this.filterItems,
            filterSubType: null,
            filterCondition: null,
            filterValue: null,
            behavioralCondition: null,
            behavioralTimes: null
        };
        //接口请求状态
        const fetchStates = {
            filterValue: {
                loading: false,
                reqId: 'fetchValueOptions'
            }
        };
        const canChoiceTimes = permissionUtils.includePermissionWithStore([PermissionConfig.userGroupTimes]);
        return {
            canChoiceTimes,
            currFilter: cloneUtils.deep(this.filter),
            states,
            visibleStates,
            fetchStates,
            options
        };
    },

    computed: {
        // 返回当前过滤器列数
        stepSpan() {
            const { filterValue, behavioralTimes } = this.visibleStates;
            let visibleLen = Object.keys(this.visibleStates).length;
            behavioralTimes !== undefined && (visibleLen -= 1);
            return filterValue ? visibleLen - 1 : visibleLen;
        },

        filterValueType() {
            const { filterItem, filterCondition } = this.states;
            return (filterItem && filterItem.cType) || (filterCondition && filterCondition.cType); //有指定组件类型则优先使用
        },

        filterValueSize() {
            const { filterItem, filterCondition } = this.states;
            return (filterItem && filterItem.size) || (filterCondition && filterCondition.size);
        },

        filterBehavioralTimes() {
            const { occurCondition, occurTimes, occurFrom, occurTo } = this.currFilter || {};
            return {
                occurCondition: occurCondition || '>=', //有部分历史数据中没有occurCondition字段
                occurTimes,
                occurFrom,
                occurTo
            };
        }
    },

    mounted() {
        this.init();
    },

    methods: {
        /**
         * 接口请求过滤器值选项列表
         */
        _fetchFilterValueOptions(funName, propertyKey) {
            return usersApis.getDictData(
                null,
                {
                    where: {
                        sid: this.sid,
                        funName,
                        propertyKey,
                        timeZone: this.timeZone,
                        limit: 5000
                    }
                },
                { requestId: this.fetchStates.filterValue.reqId + Math.random() }
            );
        },

        _fetchCampaignList() {
            return usersApis.getRelatedEngages(null, { input: { sid: this.sid } }, { requestId: this.fetchStates.filterValue.reqId + Math.random() })
        },

        /**
         * 更新当前过滤器值
         */
        _updateFilter(item, needUpdate) {
            const filter = this.currFilter;
            const {
                filterItem,
                filterSubType,
                filterCondition,
                filterValue,
                behavioralCondition,
                behavioralTimes,
                behavioralTime
            } = this.states;
            const mapper = {
                filterItem: () => {
                    const { key, defaultSubType, matchRange, propertyType, patternType, propertyFunName } =
                        filterItem || {};
                    this.updateFilter('type', key);
                    this.updateFilter('subType', defaultSubType);
                    this.updateFilter('matchRange', matchRange);
                    this.updateFilter('propertyType', (propertyType && propertyType.replace(/\-.*$/, '')) || '');
                    this.updateFilter('patternType', (patternType && patternType.replace(/\-.*$/, '')) || '');
                    this.updateFilter('value', null);
                    this.updateFilter('condition', null);
                    propertyFunName
                        ? this.updateFilter('propertyFunName', propertyFunName)
                        : delete this.currFilter.propertyFunName;
                },
                filterSubType: () => {
                    const { code } = filterSubType || {};
                    this.updateFilter('subType', code);
                    this.updateFilter('value', []);
                    this.setFilterValueOptions(true);
                    this.updateFilter('value', []);
                },
                filterCondition: () => {
                    const { code, hasValue } = filterCondition || {};
                    this.updateFilter('condition', code);

                    if (filterItem.subType && settingConfig.originalPatternList?.includes(code)) {
                        // 切换condition类型时, 判断condition 是否为originalPatternList(没有合参选项的condition列表)里的,是则重置subType为page
                        this.updateFilter('subType', 'page');
                    }
                    hasValue || this.updateFilter('value', []);
                },
                filterValue: () => {
                    const curVal =
                        (filterValue !== undefined && (Array.isArray(filterValue) ? filterValue : [filterValue])) ||
                        null;
                    this.updateFilter('value', curVal);
                },
                behavioralCondition: () => {
                    const { code } = behavioralCondition || {};
                    this.updateFilter('timesOccured', code);
                },
                behavioralTimes: () => {
                    ['occurCondition', 'occurFrom', 'occurTimes', 'occurTo'].forEach(key => {
                        this.updateFilter(key, (behavioralTimes || {})[key]);
                    });
                },
                behavioralTime: () => {
                    const { type, value } = behavioralTime || {};
                    const matchRange = type === 'timeRange' && value === 'current_session' ? value : 'history_data';
                    this.updateFilter('matchRange', matchRange);
                    this.updateFilter(type, value);
                }
            };
            mapper[item] && mapper[item]();
            needUpdate && this.notice();
        },

        _cancelFeatch(reqId) {
            axios.cancel(reqId);
        },

        init() {
            const {
                type,
                subType,
                matchRange,
                condition,
                value,
                timesOccured,
                occurCondition,
                occurFrom,
                occurTimes,
                occurTo
            } = this.currFilter;
            const filterItemCode = type ? `${type}_${matchRange}` : '';
            this.setState('filterItem', filterItemCode);
            subType && this.setFilterSubTypeOptions();
            subType && this.setState('filterSubType', subType || this.states.filterItem?.defaultSubType);
            this.setFilterConditionOptions();
            this.setState('filterCondition', condition || this.states.filterItem?.defaultCondition);
            this.setFilterValueOptions();
            this.updateStates('filterValue', value);
            this.setBehavioralConditionOptions();
            this.setBehavioralTimesOptions();
            this.setState('behavioralCondition', timesOccured);
            this.setState('behavioralTimes', '', {
                occurCondition,
                occurFrom,
                occurTimes,
                occurTo
            });
            this.updateVisibleStates();
        },
        handleDelete() {
            this.$emit('delete');
        },

        notice() {
            const { type, condition, value } = this.currFilter;
            const { filterCondition, filterValue } = this.visibleStates;
            const hasFilterItem = Boolean(type);
            const visibleFilterCondition = Boolean(filterCondition);
            const hasValue =
                filterValue === undefined ||
                (Array.isArray(value) && value.length > 0 && value.some(item => item === 0 || Boolean(item)));
            this.$set(this.currFilter, 'error', { type: !hasFilterItem, value: !hasValue });

            if (hasFilterItem && hasValue) {
                this.$emit('change', cloneUtils.deep(this.currFilter));
            }
        },

        /**
         * 获取过滤器条件选项列表
         */
        setFilterConditionOptions() {
            const { patternType } = this.states.filterItem || {};
            const options = patternType ? getNextStepForCondition(patternType) : [];
            this.updateOptions('filterCondition', options);
        },
        /**
         *
         */
        setFilterSubTypeOptions() {
            const { subType } = this.states.filterItem || {};
            const optionsVar = subType ? audienceConfig.audienceSubType[subType] : [];
            this.updateOptions('filterSubType', optionsVar);
        },
        /**
         * 设置过滤器值选项列表
         */
        async setFilterValueOptions(forceUpdate = false) {
            const { code, matchRange, valueNeedQuery, propertyFunName, key, defaultSubType, valueType, cType } =
                this.states.filterItem || {};
            if (
                this.valueOptions &&
                this.valueOptions[code] &&
                ![`visitPage_${matchRange}`, `entryPage_${matchRange}`].includes(code)
            ) {
                this.updateOptions('filterValue', this.valueOptions[code]);
                return;
            }

            let options = valueType ? audienceConfig.audienceValue[valueType] : [];
            // 国际化name
            if (['terminalType', 'visitType', 'sourceType'].includes(valueType)) {
                options = options.map(item => {
                    item.name = this.$t(item.name);
                    return item;
                });
            }
            this.fetchStates.filterValue.loading && this._cancelFeatch(this.fetchStates.filterValue.reqId);
            this.fetchStates.filterValue.loading = true;
            if (valueNeedQuery) {
                const funName = propertyFunName || key;
                const propertyKey = (propertyFunName && key) || this.currFilter.subType || defaultSubType;
                const [err, dictData] = await commonUtils.awaitWrap(
                    cType === 'campaign' ? this._fetchCampaignList() : this._fetchFilterValueOptions(funName, propertyKey)
                );
                if (err || !dictData) {
                    console.log('query conditions valuse option failed.');
                } else {
                    options = cType === 'campaign' ? dictData : dictData.reduce((acc, cur) => {
                        const { key } = cur;
                        if (!acc.some(item => item.code === key)) {
                            acc.push({
                                code: key,
                                name: key
                            });
                        }
                        return acc;
                    }, []);
                }
            }
            this.fetchStates.filterValue.loading = false;
            this.updateOptions('filterValue', options);
            key && this.$emit('updateItems', code, options);
        },

        /**
         * 设置行为条件选项列表
         */
        setBehavioralConditionOptions() {
            const { eventType } = this.states.filterItem || {};
            const options = (eventType && audienceConfig.audienceEvent[eventType]) || [];
            this.updateOptions('behavioralCondition', options);
        },

        setBehavioralTimesOptions() {
            this.updateOptions('behavioralTimes', audienceConfig.audienceEvent.visitTime);
        },

        /**
         * 设置状态值
         */
        setState(type, code, val) {
            const options = this.options[type] || [];
            const itemInfo = val || (code && searchTree(options, code));
            this.updateStates(type, itemInfo);
        },

        updateStates(key, value = null) {
            if (Object.prototype.hasOwnProperty.call(this.states, key)) {
                let defaultValue = null;
                if (key === 'behavioralCondition') {
                    defaultValue = (this.options?.behavioralCondition || []).find(item => item.defaultValue);
                }
                this.states[key] = value ?? defaultValue;
            }
        },

        updateOptions(key, value = []) {
            if (Object.prototype.hasOwnProperty.call(this.options, key)) {
                this.options[key] = value;
            }
        },

        /**
         * 更新组件显示状态
         */
        updateVisibleStates() {
            const { filterItem, filterValue, filterCondition, behavioralCondition } = this.states;
            const { eventType, patternType, matchRange } = filterItem || {};
            const hasFilterValue = Boolean(filterCondition) ? Boolean(filterValue) : true;
            this.visibleStates = {};

            //只保留会展示的组件
            Object.keys(this.states).forEach(key => {
                let visible = true;
                let hasComponent = true;
                switch (key) {
                    case 'filterCondition':
                        hasComponent = Boolean(patternType);
                        visible = Boolean(filterItem);
                        break;
                    case 'filterValue':
                        const { hasValue } = filterCondition || {};
                        hasComponent = hasValue;
                        visible = Boolean(filterItem);
                        break;
                    case 'behavioralCondition':
                        hasComponent = Boolean(audienceConfig.audienceEvent[eventType]);
                        visible = hasFilterValue && eventType;
                        break;
                    case 'behavioralTimes':
                        hasComponent = (this.options?.behavioralCondition || []).some(
                            item => item.code === behavioralCondition?.code && item.hasTimes
                        );
                        visible = this.visibleStates.behavioralCondition;
                        break;
                    case 'behavioralTime':
                        hasComponent = matchRange === 'history_data';
                        visible = hasFilterValue;
                        break;
                }
                hasComponent && (this.visibleStates[key] = visible);
            });
        },

        updateFilter(key, value) {
            this.$set(this.currFilter, key, value);
        },

        /**
         * 更新过滤器值
         */
        handleChange(type, value) {
            let needUpdate = true;
            switch (type) {
                case 'filterItem':
                    const { defaultCondition, subType, defaultSubType } = value;
                    this.updateStates('filterItem', value);
                    subType && this.setFilterSubTypeOptions();
                    subType && defaultSubType && this.setState('filterSubType', defaultSubType);
                    this.setFilterConditionOptions();
                    this.setState('filterCondition', defaultCondition);
                    this.setFilterValueOptions();
                    this.updateStates('filterValue');
                    this.setBehavioralConditionOptions();
                    this.setBehavioralTimesOptions();
                    this.updateStates('behavioralCondition');
                    this.updateStates('behavioralTimes');
                    this.updateVisibleStates();

                    // 如果没有Condition，则说明切换item即为新增完成
                    needUpdate = (this.options.filterCondition || []).length === 0;
                    break;
                case 'filterCondition':
                case 'filterValue':
                case 'behavioralCondition':
                    this.updateStates(type, value);
                    this.updateVisibleStates();

                    type === 'filterValue' &&
                        this.updateStates('behavioralTimes', {
                            occurCondition: this.currFilter.occurCondition,
                            occurFrom: this.currFilter.occurFrom,
                            occurTimes: this.currFilter.occurTimes,
                            occurTo: this.currFilter.occurTo
                        });
                    break;
                default:
                    this.updateStates(type, value);
                    break;
            }
            this._updateFilter(type, needUpdate);
        }
    },

    watch: {
        filter(val) {
            this.currFilter = cloneUtils.deep(val);
            this.init();
        }
    },

    components: {
        FilterItem,
        FilterSubType,
        FilterCondition,
        FilterValue,
        BehavioralCondition,
        BehavioralTimes,
        BehavioralTime
    }
};
</script>

<style lang="scss">
@import '@/styles/import.scss';

.filter {
    display: flex;
    margin-bottom: 10px;
    flex-wrap: wrap;

    &-item {
        flex: 0 1 220px;
        min-width: 220px;
        padding-right: 12px;
        position: relative;
        margin-bottom: 10px;

        &:last-child {
            padding-right: 0;
        }

        &:nth-last-of-type(2) {
            flex: 1;
        }

        &:first-of-type {
            flex: 0 1 220px;
        }

        &[data-span='3'] {
            flex: 0 0 calc(100% - (220px * 2) - 20px);
        }

        &[data-span='4'] {
            flex: 0 0 calc(100% - (220px * 2) - 260px - 20px);
        }

        &[data-span='5'] {
            flex: 0 0 calc(100% - (220px * 2) - 260px - 20px);
        }
        &--flex {
            display: flex;

            .filter-item__step:not(:last-of-type) {
                margin-right: 12px;
            }
        }

        &--260 {
            flex: 0 0 260px;
        }

        &--event {
            max-width: 252px;
        }

        &__timeRange {
            display: flex;
            flex-direction: column;

            &-picker {
                width: 100% !important;
            }
        }

        &__times {
            display: flex;
            align-items: center;

            .filter-item__step {
                margin: 0 !important;
            }
        }

        &__error-tips {
            color: #f66;
            position: absolute;
            top: 100%;
            left: 0;
        }

        &__item {
            width: 100%;
            height: 100%;
        }

        &__title {
            width: 100%;
            height: 32px;
            line-height: 32px;
            border: 1px solid $pt-black-50;
            background-color: $pt-white;
            border-radius: 4px;
            padding: 0 30px 0 15px;
            position: relative;
            cursor: pointer;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);

            &-suffix {
                position: absolute;
                right: 15px;
                top: 50%;
                transform: translateY(-50%);
            }

            &.focus {
                border-color: $pt-green-60;
            }
        }

        .placeholder {
            color: $pt-black-50;
        }

        &__popover {
            width: 100%;

            &-container {
                margin: 4px 12px 0 0;
                position: absolute;
                top: 100%;
                left: 0;
                right: 0;
                &.top-placement {
                    top: unset;
                    bottom: 100%;
                }
            }

            .el-select {
                width: 100%;
            }
            .filter-item__step {
                padding-right: 0;
                margin-bottom: 8px;
                line-height: 1;
            }

            .activeOption {
                color: $pt-green-60;
            }
        }

        &__container {
            display: flex;
            color: $pt-black-90;
            font-size: 14px;
            align-items: center;

            .filter-item__step {
                min-width: 0;
                padding-right: 8px;
                flex: 1;
                margin-bottom: 0;

                &:nth-child(2) {
                    width: 30%;
                }
            }
            p {
                color: $pt-black-600;
                text-align: left;
                font-size: 12px;
            }
        }

        &__suffix {
            width: 40px;
        }

        &__step {
            flex: 1;

            &--96 {
                flex: 0 0 96px;
            }
        }

        .el-popover {
            background-color: $pt-white;
        }
    }

    .el-tag.el-tag--info {
        max-width: 100%;
        display: flex;
        align-content: center;

        .el-select__tags-text {
            width: calc(100% - 16px);
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .el-tag__close {
            top: 5px;
        }
    }

    .button-remove {
        width: 20px;
        height: 20px;
        margin-top: 4px;
        cursor: pointer;

        &:hover {
            svg {
                fill: $pt-black-600;
            }
        }
        svg {
            fill: $pt-black-300;
        }
    }
}

.filter-item {
    &__popper {
        background-color: $pt-white;
        padding: 0;
    }

    .el-dropdown,
    .el-select,
    .el-autocomplete {
        width: 100%;
    }

    &__content {
        .el-date-editor.el-input,
        .el-date-editor.el-input__inner {
            width: 100%;
        }
    }
}
</style>
