<template>
    <filter-step-item
        :size="size"
        :allow-create="true"
        :placeholder="placeholder"
        :cType="cType"
        :sid="sid"
        :currFilter="currFilter"
        :value="currentValue"
        :filter-item="filterItem"
        :remoteLoading="optionsLoading"
        :expandAfterInit="expandAfterInit"
        :options="options"
        @change="handleChange"
    ></filter-step-item>
</template>

<script>
import axios from 'axios';
import commonUtils from '@/common/utils/common.utils';
import audienceConfig from '../../../usergrp-config';
import FilterStepItem from './FilterStepItem';

export default {
    name: 'FilterValue',

    props: {
        size: String,
        sid: String,
        timeZone: String,
        value: Array,
        options: Array,
        optionsLoading: Boolean,
        cType: String,
        filterItem: Object,
        filterCondition: Object,
        currFilter: Object,
        expandAfterInit: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            itemName: 'filterValue',
            currentValue: null,
            loading: true
        };
    },

    computed: {
        placeholder() {
            const { name } = this.filterItem;
            return this.$t('audience.placeholder', { type: this.$t(name) || '' });
        }
    },

    mounted() {
        this.currentValue = this.getCurValue(this.cType, this.value);
    },

    methods: {
        getCurValue(cType, value) {
            const cTypeIsArray = ['multiple', 'inputDobuleNumber', 'dobuleDate', 'campaign'].includes(cType);
            const valueIsArray = Array.isArray(value);
            let currentValue = null;
            if (value) {
                if (cTypeIsArray) {
                    currentValue = valueIsArray ? value : [value];
                } else {
                    currentValue = valueIsArray ? value[0] : value;
                }
            }
            return currentValue;
        },

        handleChange(val) {
            this.currentValue = val;
            this.$emit('change', this.itemName, val);
        }
    },

    watch: {
        cType(newVal, oldVal) {
            // 数值切换至数值组时，默认递增4。如果空值，则默认为[1, 5];
            if (oldVal === 'inputNumber' && newVal === 'inputDobuleNumber') {
                const currentValue = this.getCurValue(newVal, this.value);
                const defaultMin = (currentValue || [])[0];
                const min = defaultMin || 1;
                const max = min + 4;
                this.handleChange([min, max]);
            } else if (oldVal === 'inputDobuleNumber' && newVal === 'inputNumber') {
                this.handleChange((this.currentValue || []).slice(0, 1));
            } else {
                this.handleChange();
            }
        },

        value(val) {
            this.currentValue = this.getCurValue(this.cType, val);
        }
    },

    components: {
        FilterStepItem
    }
};
</script>
