<template>
    <pt-input-number
        :size="size"
        :min="minValue"
        :unit="unit"
        v-model="currValue"
        @change="handleChange"
    ></pt-input-number>
</template>

<script>
export default {
    name: 'inputNumberItem',

    props: {
        size: {
            type: String,
            default: 'large'
        },
        value: {
            required: true
        },
        minValue: Number,
        unit: String
    },

    data() {
        return {
            currValue: this.value || ''
        };
    },

    methods: {
        handleChange(val) {
            this.$emit('change', val);
        }
    },

    watch: {
        value(val) {
            this.currValue = val;
        }
    }
};
</script>
