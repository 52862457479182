import userGrpConfig from './usergrp-config';
import settingConfig from '@/components/campaign/setting/setting.config';
import uuidUtil from '@/common/utils/uuid.utils';
import cloneUtils from '@/common/utils/clone.utils';
import dayjs from 'dayjs';

export const getConditionForString = function(filedType, conditionConfigs) {
    if (!filedType) return null;
    filedType = filedType.toLowerCase();

    return conditionConfigs.string.filter(item => {
        switch (filedType) {
            case 'string-a':
                return ['[()]', '![()]', '==', '!='].includes(item.code);
            case 'string-b':
                return ['==', '!='].includes(item.code);
            case 'string-c':
                return ['[()]', '![()]', '==', '!=', '[(', ')]', 'Rex'].includes(item.code);
            case 'string-d':
                return ['[()]', '==', '[(', ')]', 'Rex'].includes(item.code);
            default:
                return true;
        }
    });
};

export const getConditionForNumber = function(filedType, conditionConfigs) {
    if (!filedType) return null;
    filedType = filedType.toLowerCase();

    return conditionConfigs.number.filter(item => {
        switch (filedType) {
            case 'number-a':
                return ['>', '<', '>=', '<=', '!=', '==', 'between'].includes(item.code);
            default:
                return true;
        }
    });
};

export const getConditionForTime = function(filedType, conditionConfigs) {
    if (!filedType) return null;
    filedType = filedType.toLowerCase();

    return conditionConfigs.time.filter(item => {
        switch (filedType) {
            case 'time-a':
                return ['today', '>=', '<=', '==', '!=', '>==', '<==', '===', 'between'].includes(item.code);
            default:
                return true;
        }
    });
};

export const getNextStepForCondition = function(filedType, isGoalEvent) {
    if (!filedType) return null;
    filedType = filedType.toLowerCase();
    const conditionConfigs = !isGoalEvent ? userGrpConfig.audienceCondition : userGrpConfig.goalEventCondition;
    if (filedType.includes('time')) {
        return getConditionForTime(filedType, conditionConfigs);
    } else if (filedType.includes('number')) {
        return getConditionForNumber(filedType, conditionConfigs);
    } else {
        return getConditionForString(filedType, conditionConfigs);
    }
};

export const getAllAuthTypeList = function() {
    return userGrpConfig.audienceType.reduce((acc, cur) => {
        if (cur.options) {
            let list = cur.options.filter(item => item.authType);
            acc.push(...list);
        } else if (cur.authType) {
            acc.push(cur);
        }
        return acc;
    }, []);
};

export const isInvalidTargetHistory = function(target, firstDataRecord) {
    if (!firstDataRecord) return true;

    const timezone = window.pt.global.timezone;
    const firstDataRecordValue = Number(firstDataRecord);
    const timeRange = target.timeRange;
    let timeFrom = new Date(target.timeFrom + 'GMT ' + timezone).getTime();

    switch (timeRange) {
        case 'thisWeek':
            timeFrom = new Date(
                dayjs()
                    .day('Monday')
                    .format('YYYY-MM-DD') +
                    'GMT ' +
                    timezone
            ).valueOf();
            break;
        case 'thisMonth':
            timeFrom = new Date(
                dayjs()
                    .date(1)
                    .format('YYYY-MM-DD') +
                    'GMT ' +
                    timezone
            ).valueOf();
            break;
        case 'lastDays':
            const timeDays = target.timeWithToday ? target.timeDays - 1 : target.timeDays;
            timeFrom = new Date(
                dayjs()
                    .subtract(timeDays, 'days')
                    .format('YYYY-MM-DD') +
                    'GMT ' +
                    timezone
            ).valueOf();
            break;
        case 'today':
            timeFrom = 0;
            break;
    }
    console.log('timeFrom', timeRange, timeFrom, firstDataRecordValue);
    return timeFrom < firstDataRecordValue;
};

//获取默认filter信息
export const getDefaultValue = function() {
    return {
        uuid: uuidUtil.uuid(),
        timeDays: '7',
        timeRange: 'lastDays',
        matchRange: 'history_data',
        occurCondition: '>=',
        occurTimes: 1,
        occurFrom: 1,
        occurTo: 5,
        timeWithToday: true,
        timeFrom: dayjs()
            .subtract(6, 'days')
            .format('YYYY/MM/DD'),
        timeTo: dayjs().format('YYYY/MM/DD'),
        error: {
            type: true,
            value: false
        }
    };
};

const getDefaultUsers = function(type) {
    return {
        uuid: uuidUtil.uuid(),
        timeDays: '7',
        timeRange: 'lastDays',
        matchRange: 'user_property',
        occurTimes: '1',
        occurFrom: '1',
        occurTo: '5',
        timeWithToday: true,
        timeFrom: dayjs()
            .subtract(7, 'days')
            .format('YYYY/MM/DD'),
        timeTo: dayjs().format('YYYY/MM/DD'),
        error: {
            type: false,
            value: false
        },
        type: 'distinct user ID',
        propertyType: 'STATIC',
        patternType: 'STRING',
        value: ['not set'],
        condition: type === 'anonymous' ? '==' : '!=',
        propertyFunName: 'userPropertyValue'
    };
};

//获取默认current session filter 信息
export const getDefaultSessionValue = function() {
    return {
        uuid: uuidUtil.uuid(),
        matchRange: 'current_session',
        occurCondition: '>=',
        occurTimes: 1,
        occurFrom: 1,
        occurTo: 5,
        error: {
            type: true,
            value: false
        }
    };
};
//获取默认filter
export const getDefaultFilter = function(type = 'history') {
    let filterChildren = [];
    if (['anonymous', 'known'].includes(type)) {
        filterChildren.push(getDefaultUsers(type));
    } else {
        filterChildren.push(getDefaultValue());
    }

    return {
        children: filterChildren,
        operator: 'and'
    };
};

/**
 *  处理usergroup历史数据 visitPage entryPage 没有subType值得 默认给page
 * @param usergroups
 * @returns
 */
export const initUsergroupData = function(usergroups) {
    if (!usergroups) return null;
    const cloneUsergroups = cloneUtils.deep(usergroups);
    if (cloneUsergroups?.children?.length > 0) {
        cloneUsergroups?.children?.map(item => {
            if (['visitPage', 'entryPage'].includes(item.type) && !item.subType) {
                item.subType = 'page';
            }
            // 历史数据兼容  如果condition 类型为 包含 不包含 正则 头匹配 则只有原始页面类型
            if (settingConfig.originalPatternList.includes(item.condition) && item.subType) {
                item.subType = 'page';
            }
        });
    }
    return cloneUsergroups;
};

export const searchTree = function(tree, code, label = 'code') {
    if (!Array.isArray(tree) || code === null || code === undefined) return null;

    for (let i = 0, max = tree.length; i < max; i++) {
        let item = tree[i];
        if (item[label] === code) {
            return item;
        } else if (item.options && Array.isArray(item.options)) {
            let result = searchTree(item.options, code, label);
            if (result) return result;
        }
    }
    return null;
};

export function formatVersionLabel(version, text){
    const { status, versionName } = version;
    return `${versionName}${status === 0 ? text : ''}`;
}
