<template>
    <div class="filter-item popoverWithMask" :data-span="stepSpan">
        <slot name="filterValue" v-if="currFilter.type === 'eventName'"></slot>

        <div class="filter-item__popover" v-else v-pt-clickoutside="handleClickOutside">
            <div
                ref="conditionRef"
                class="filter-item__title"
                :class="[!visibleTitle && 'placeholder', visiblePopver && 'focus']"
                @click="togglePopver(!visiblePopver)"
            >
                <div :class="$style.valueTitle" class="valueTitle" v-html="valueTitle" v-if="valueTitle" :title="filterValue"></div>
                <span :class="$style.valuePlaceholder" v-else>{{ $t('common.multiple_explain') }}</span>
                <span class="filter-item__title-suffix">
                    <i class="el-icon-arrow-down"></i>
                </span>
                <div :class="$style.valueCount" v-if="visibleCount > 0">
                    <p :class="$style.stateNumber">+ {{ visibleCount }}</p>
                </div>
            </div>
            <transition name="el-fade-in-linear" v-if="visiblePopver">
                <div
                    :class="[
                        'el-popover',
                        'el-popper',
                        'filter-item__popover-container',
                    ]"
                >
                    <filter-step-item
                        v-if="!['userGroup', 'triggerEngage'].includes(currFilter.type)"
                        cType="select"
                        :size="size"
                        :value="currentValue"
                        :filter-item="filterItem"
                        :currFilter="currFilter"
                        :options="options"
                        :expandAfterInit="true"
                        @change="handleChange"
                    ></filter-step-item>
                    <slot name="filterSubType" v-if="!originalPatternList.includes(currentValue) && !['userGroup', 'triggerEngage'].includes(currFilter.type)"></slot>
                    <slot name="filterValue"></slot>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import { searchTree, formatVersionLabel } from '@/components/users/usergrp-util';
import FilterStepItem from './FilterStepItem';
import audienceConfig from '@/components/users/usergrp-config';
import settingConfig from '@/components/campaign/setting/setting.config';
import domUtils from '@/common/utils/dom.utils';

export default {
    name: 'FilterCondition',

    props: {
        size: String,
        value: String,
        options: Array,
        filterItem: {
            type: Object,
            required: true
        },
        currFilter: Object,
        filterValue: Array,
        filterValueOptions: Array,
        cType: String,
        stepSpan: Number,
        placement: {
            type: String,
            default: () => 'bottom'
        }
    },

    data() {
        return {
            itemName: 'filterCondition',
            currentValue: this.value,
            conditionInfo: null,
            popoverWidth: 0,
            popperOptions: null,
            visiblePopver: false,
            originalPatternList: settingConfig.originalPatternList,
            visibleCount: 0
        };
    },

    computed: {
        // hasValue仅标识当前条件是否需要校验value
        visibleTitle() {
            const { hasValue } = this.conditionInfo || {};
            return hasValue ? this.filterHasValue : true;
        },

        filterHasValue() {
            return Array.isArray(this.filterValue) ? this.filterValue.length > 0 : this.filterValue ?? false;
        },

        valueTitle() {
            const value = this.filterValue;
            const condition = searchTree(this.options, this.currentValue);
            const { name, code } = condition || {};
            const { subType } = this.currFilter || {};
            const { subType: subTypeItem, patternType } = this.filterItem;
            const hasValueTextCode = [
                {
                    code: '===',
                    i18nCode: 'common.date_exactly_for_value'
                },
                {
                    code: '<==',
                    i18nCode: 'common.date_less_than_value'
                },
                {
                    code: '>==',
                    i18nCode: 'common.date_more_than_value'
                }
            ];
            const valueTitleInfo = hasValueTextCode.find(item => item.code === code);
            let audienceValue = value || '';
            if (Array.isArray(value)) {
                let audienceValueList = null;
                if(this.cType === 'campaign') {
                    if(this.filterValueOptions) {
                        const engageInfo = []
                        value.forEach((info) => {
                            const [engageId, versionId] = info.split('&');
                            const engage = this.filterValueOptions.find((option) => option.engageId === engageId);
                            const version = engage?.versions?.find((version) => version.versionId === versionId);
                            if(engage && version) {
                                engageInfo.push(domUtils.getConditionTitle(`${engage.engageName}-${formatVersionLabel(version, this.$t('placeholder.deleted_version'))}`))
                            }
                        })
                        audienceValueList = engageInfo;
                    }
                } else {
                    audienceValueList = value.map(item => {
                        let itemName = this.filterValueOptions && this.filterValueOptions.find(op => op.code === item);
                        const itemHtml = domUtils.getConditionTitle((itemName && this.$t(itemName.name)) || item);
                        return itemHtml;
                    });
                }
                const sepreateWord = (patternType === 'TIME' || patternType === 'NUMBER' || patternType === 'TIME-A' || patternType === 'NUMBER-A') && this.value === 'between'
                ? ' - '
                : ['![()]', '!='].includes(this.currentValue)
                    ? this.$t('common.and_lowercase')
                    : this.$t('common.or');
                    audienceValue = (audienceValueList && audienceValueList.join(domUtils.getConditionSepreateWord(sepreateWord))) || '';
                }

            let subTypeText = '';
            if (subType) {
                subTypeText = audienceConfig.audienceSubType?.[subTypeItem]?.find(item => item.code === subType)?.name;
            }
            return valueTitleInfo
                ? `<span class="firstWord">${subTypeText ? this.$t(subTypeText) + ' ' : ''}</span>${this.$t(valueTitleInfo.i18nCode, {
                      val: audienceValue
                  })}`
                : `<span class="firstWord">${this.$t(name)} ${
                      subTypeText && !this.originalPatternList.includes(code) ? this.$t(subTypeText) + ' ' : ''
                  }</span> ${audienceValue}`;
        },

        valuePlaceholder() {
            const { name } = this.filterItem || {};
            return this.$t('audience.placeholder', { type: this.$t(name) });
        }
    },

    created() {
        this.init();
        this.$eventBus.$on('handleReportFilter', this.handleReportFilter);
    },
    beforeDestroy() {
        this.$eventBus.$off('handleReportFilter', this.handleReportFilter);
    },

    methods: {
        init() {
            const { patternType, defaultCondition, condition } = this.filterItem;
            const code = this.value || condition || defaultCondition;
            const defaultConditionInfo = this.options.find(item => item.defaultValue);
            const conditionInfo = (code && this.options.find(item => item.code === code)) || defaultConditionInfo;
            this.visiblePopver = false;
            this.conditionInfo = conditionInfo;
            this.currentValue = (conditionInfo && conditionInfo.code) || '';
            this.handleChange(this.currentValue);
        },

        handleChange(val) {
            const item = searchTree(this.options, val);
            this.currentValue = val;
            this.conditionInfo = item;
            this.$emit('change', this.itemName, item);
            this.handleWord();
        },

        handleClickOutside() {
            // 预制50ms给多选下拉失焦后创建动作
            setTimeout(() => {
                this.togglePopver(false);
            }, 50);
        },

        togglePopver(status) {
            this.visiblePopver = status;
            this.handleWord();
        },

        handleWord(){
            domUtils.resetDom(this.$refs.conditionRef);
            this.$nextTick(() => {
                if(this.$refs.conditionRef){
                    this.visibleCount = domUtils.getConditionCount(this.$refs.conditionRef);
                }
            });
        },

        handleReportFilter(val) {
            if(val){
                this.handleWord();
            }
        }
    },

    watch: {
        filterItem: {
            handler: function() {
                this.init();
                this.handleChange(this.currentValue);
            },
            deep: true
        }
    },

    components: {
        FilterStepItem
    }
};
</script>
<style lang="scss" module>
@import '@/styles/import.scss';
    :global {
        .popoverWithMask {
            .filter-item__popover-container {
                margin: 0;
                top: -14px;
            }

            .filter-item__title {
                padding: 0 50px 0 15px !important;
            }
            .filter-item__title-suffix {
                right: 6px !important;
            }
            .conditionKeyWord {
                box-sizing: border-box;
                border-color: transparent;
                margin: 0 2px;
                background-color: #f2f7f2;
                height: 24px;
                padding: 0 8px;
                line-height: 24px;
                white-space: nowrap;
                border-radius: 4px;
                font-size: 13px;
                color: #091e42;
                max-width: 100%;
                display: block;
            }
            .ellipsisWord {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }

.valueTitle {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: flex;
    align-items: center;
    height: 30px;
    :global {
        .conditionSepreateWord {
            margin: 0 2px;
            font-size: 13px;
            color: #8993a4;
        }
    }
}
.valuePlaceholder {
    display: inline-block;
    line-height: 30px;
    font-size: 13px;
    color: #8993A4;
}
.valueCount {
    position: absolute;
    right: 22px;
    top: 1px;
    display: flex;
    align-items: center;
    .stateNumber {
        line-height: 28px;
    }
}

</style>
