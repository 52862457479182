<template>
    <div class="filter-item">
        <filter-step-item
            cType="select"
            :sid="sid"
            :size="size"
            :value="currentValue.occurCondition"
            :options="options"
            @change="value => handleChange('occurCondition', value)"
        ></filter-step-item>

        <div class="filter-item__times">
            <filter-step-item
                cType="inputNumber"
                :sid="sid"
                :size="size"
                :value="currentValue.occurTimes || currentValue.occurFrom"
                :min-value="1"
                @change="value => handleChange('occurTimes', value)"
            ></filter-step-item>
            <template v-if="valueIsBetween">
                <span class="gap">-</span>
                <filter-step-item
                    cType="inputNumber"
                    :sid="sid"
                    :size="size"
                    :value="currentValue.occurTo"
                    :min-value="1"
                    @change="value => handleChange('occurTo', value)"
                >
                </filter-step-item>
            </template>
            <div class="suffix">{{ $t('common.times') }}</div>
        </div>
    </div>
</template>

<script>
import FilterStepItem from './FilterStepItem';

export default {
    name: 'BehavioralTimes',

    props: {
        sid: String,
        size: String,
        value: Object,
        options: Array,
        filterItem: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            itemName: 'behavioralTimes',
            currentValue: {}
        };
    },

    computed: {
        valueIsBetween() {
            return this.currentValue?.occurCondition === 'between';
        }
    },

    created() {
        this.init();
    },

    methods: {
        init() {
            this.currentValue = Object.assign(
                {},
                {
                    occurCondition: '>=',
                    occurTimes: 1,
                    occurFrom: 1,
                    occurTo: 5
                },
                this.value
            );
        },

        handleChange(type, val) {
            type === 'occurTimes' && (this.currentValue.occurFrom = val);
            this.currentValue[type] = val;

            const { occurCondition, occurTimes, occurFrom, occurTo } = this.currentValue;
            const value = {
                occurCondition,
                occurTimes,
                occurFrom,
                occurTo
            };
            this.$emit('change', this.itemName, value);
        }
    },

    watch: {
        filterItem() {
            this.init();
        }
    },

    components: {
        FilterStepItem
    }
};
</script>

<style lang="scss">
.gap {
    margin: 0 6px;
}
.suffix {
    margin-left: 6px;
}
</style>
