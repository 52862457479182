<template>
    <div class="filter-item">
        <filter-step-item
            v-if="options"
            cType="timeRange"
            :sid="sid"
            :value="currentValue"
            :options="options"
            @change="handleChange"
        ></filter-step-item>
    </div>
</template>

<script>
import audienceConfig from '../../../usergrp-config';
import FilterStepItem from './FilterStepItem';

export default {
    name: 'BehavioralTime',

    props: {
        sid: String,
        value: Object,
        filterItem: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            itemName: 'behavioralTime',
            currentValue: this.value
        };
    },

    computed: {
        options() {
            const { code } = this.filterItem;
            const options = audienceConfig.audienceTargetHistory;
            const defaultValue = options && options.find(item => item.defaultValue).code;
            return code === 'visitWebsite' ? options.filter(item => item.matchRange === 'history_data') : options;
        },

        itemOptions() {
            return {
                value: {
                    timeRange: this.value || defaultValue
                },
                options: this.options
            };
        }
    },

    created() {
        this.init();
    },

    methods: {
        init() {
            this.currentValue = this.value;
        },

        handleChange({ type, value }) {
            this.$emit('change', this.itemName, {
                type,
                value
            });
        }
    },

    watch: {
        filterItem() {
            this.init();
        }
    },

    components: {
        FilterStepItem
    }
};
</script>
