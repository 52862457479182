<template>
    <pt-multi-select
        :size="size"
        :value="currValue"
        :value-key="valueKey"
        :options="options"
        :remote="remote"
        :allow-create="allowCreate"
        :placeholder="placeholder"
        :tag-placeholder="
            currFilter && ['![()]', '!='].includes(currFilter.condition) ? $t('common.and_lowercase') : $t('common.or')
        "
        :loading="remoteLoading"
        :remote-method="remoteMethod"
        :blur-create="true"
        :trim-space="needTrimSpace"
        :allow-empty="false"
        :expandAfterInit="expandAfterInit"
        @change="handleChange"
    ></pt-multi-select>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    name: 'multipleItem',

    props: {
        size: String,
        value: {
            default: function() {
                return [];
            },
            required: true
        },
        valueKey: {
            type: String,
            default: 'code'
        },
        currFilter: Object,
        options: Array,
        placeholder: String,
        remote: Boolean,
        allowCreate: Boolean,
        remoteLoading: Boolean,
        expandAfterInit: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            currValue: this.value || []
        };
    },

    methods: {
        remoteMethod(query) {
            console.log(query);
        },

        handleChange(values) {
            let valueArray = values.map(item => item.code);
            this.$emit('change', valueArray, values);
        }
    },
    computed: {
        needTrimSpace() {
            return ['visitPage', 'entryPage', 'sourceUrl', 'sourceHost'].includes(this.currFilter?.type);
        }
    },
    watch: {
        value(val) {
            this.currValue = val || [];
        }
    }
};
</script>
