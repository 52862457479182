<template>
    <div class="filter-item filter-item--260">
        <div class="filter-item__popover" v-pt-clickoutside="handleClickOutside" v-if="showTimes">
            <div class="filter-item__title" :class="[visiblePopver && 'focus']" @click="togglePopver(!visiblePopver)">
                {{ valueTitle }}
                <span class="filter-item__title-suffix">
                    <i class="el-icon-arrow-down"></i>
                </span>
            </div>
            <transition name="el-fade-in-linear" v-if="visiblePopver">
                <div class="el-popover el-popper filter-item__popover-container">
                    <filter-step-item
                        cType="select"
                        :sid="sid"
                        :size="size"
                        :value="currentValue"
                        :options="options"
                        @change="handleChange"
                    ></filter-step-item>

                    <slot name="behavioralTimes"></slot>
                </div>
            </transition>
        </div>
        <filter-step-item
            v-else
            cType="select"
            :sid="sid"
            :size="size"
            :value="currentValue"
            :options="options"
            @change="handleChange"
        ></filter-step-item>
    </div>
</template>

<script>
import { searchTree } from '../../../usergrp-util';
import audienceConfig from '../../../usergrp-config';
import FilterStepItem from './FilterStepItem';

export default {
    name: 'BehavioralCondition',

    props: {
        sid: String,
        size: String,
        value: String,
        options: Array,
        filterItem: Object,
        filterBehavioralTimes: Object,
        showTimes: Boolean
    },

    data() {
        return {
            itemName: 'behavioralCondition',
            currentValue: null,
            visiblePopver: false
        };
    },

    computed: {
        valueTitle() {
            const item = searchTree(this.options, this.currentValue);
            const { name } = item || {};
            const { occurCondition, occurFrom, occurTimes, occurTo } = this.filterBehavioralTimes || {};
            const timesText =
                occurCondition === 'between' ? `${occurFrom} - ${occurTo}` : `${occurCondition} ${occurTimes}`;
            return name && `${this.$t(name)} ${timesText} ${this.$t('common.times')}`;
        }
    },

    created() {
        this.init();
    },

    methods: {
        init() {
            const { eventType } = this.filterItem || {};
            const defaultValue = (this.options || []).find(item => item.defaultValue).code;
            this.currentValue = this.value || defaultValue;
        },

        handleChange(val) {
            const item = searchTree(this.options, val);
            this.currentValue = val;
            this.$emit('change', this.itemName, item);
        },

        handleClickOutside() {
            this.togglePopver(false);
        },

        togglePopver(status) {
            this.visiblePopver = status;
        }
    },

    watch: {
        filterItem: {
            handler: function() {
                this.init();
            }
        }
    },

    components: {
        FilterStepItem
    }
};
</script>
