<template>
    <!-- 禁止滚动事件传播，引起页面滚动条滚动 -->
    <div class="filter-item" @mousewheel.stop @wheel.stop>
        <filter-step-item
            cType="select"
            :size="size"
            :value="currentValue"
            :options="items"
            :sid="sid"
            :placeholder="$t('common.select_placeholder')"
            @change="handleChange"
        ></filter-step-item>
    </div>
</template>

<script>
import { searchTree } from '../../../usergrp-util';
import FilterStepItem from './FilterStepItem';

export default {
    name: 'FilterItem',

    props: {
        size: String,
        value: String,
        matchRange: String,
        items: Array,
        sid: String
    },

    data() {
        return {
            itemName: 'filterItem',
            currentValue: null
        };
    },

    methods: {
        handleChange(val) {
            const item = searchTree(this.items, val);
            this.currentValue = val;
            this.$emit('change', this.itemName, item);
        }
    },

    watch: {
        value: {
            // 由于filter type有重名可能，现在设置组件的code为type_matchRange组合生成
            handler: function(val) {
                this.currentValue = val && `${val}_${this.matchRange}`;
            },
            immediate: true
        }
    },

    components: {
        FilterStepItem
    }
};
</script>
