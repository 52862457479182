<template>
    <div class="filter-item__step">
        <component
            value-key="code"
            :sid="sid"
            :cName="cName"
            :is="currentView"
            :size="size"
            :value="value"
            :currFilter="currFilter"
            :filter-item="filterItem"
            :min-value="minValue"
            :max-day-limit="maxDayLimit"
            :unit="unit"
            :options="options"
            :remote="remote"
            :allow-create="allowCreate"
            :placeholder="placeholder"
            :remote-loading="remoteLoading"
            :expandAfterInit="expandAfterInit"
            @change="handleChange"
        ></component>

        <slot name="error"></slot>
    </div>
</template>

<script>
import baseConfig from '@/common/configs/base.config';
let components = [];
function importAll(r) {
    r.keys().forEach(key => {
        components.push(r(key));
    });
}
importAll(require.context('../items', true, /\.vue$/));

export default {
    name: 'FilterStepItem',

    props: {
        sid: String,
        size: String,
        cType: {
            type: String,
            required: true
        },
        cName: {
            type: String,
            default: ''
        },
        value: {
            required: true
        },
        filterItem: {
            type: Object
        },
        currFilter: {
            type: Object
        },
        placeholder: String,
        options: Array,
        remote: Boolean,
        remoteLoading: Boolean,
        minValue: Number,
        unit: String,
        allowCreate: {
            type: Boolean,
            default: false
        },
        cannotCreateOngoing: Boolean,
        firstItemType: String,
        expandAfterInit: {
            // 组件库里新增字段，用于多选输入框出现的时候默认focus和出现下拉框
            type: Boolean,
            default: false
        }
    },

    provide() {
        return {
            root: this
        };
    },

    data() {
        return {
            currentView: null,
            components: components
        };
    },

    created() {
        this.currentView = this.getComponentByName(this.cType);
    },

    computed: {
        maxDayLimit() {
            return baseConfig.VIP_PROFILES.userQueryDayLimit.includes(this.sid) ? 366 : 60;
        }
    },

    methods: {
        /**
         * 根据名称获取组件
         * @param name
         * @returns {*}
         */
        getComponentByName(name) {
            let component = this.components.find(c => c.default.name === name + 'Item');
            return component && component.default;
        },

        handleChange(val) {
            this.$emit('change', val);
        }
    },

    watch: {
        cType(newVal) {
            this.currentView = this.getComponentByName(newVal);
        }
    }
};
</script>
