<template>
    <el-date-picker
        class="filter-item__timeRange-picker"
        size="small"
        type="date"
        format="yyyy/MM/dd"
        value-format="yyyy/MM/dd"
        :clearable="false"
        :editable="false"
        :picker-options="pickerOptions"
        v-model="currValue"
        @change="handleChange"
    ></el-date-picker>
</template>

<script>
import dayjs from 'dayjs';
import dateUtils from '@/common/utils/date.utils';

export default {
    name: 'dateItem',

    props: {
        value: {
            required: true
        },
        filterItem: Object
    },

    data() {
        const notDisableDate = this.filterItem?.notDisableDate;
        return {
            currValue: this.value,
            pickerOptions: {
                disabledDate(val) {
                    const timezone = pt.global.timezone;
                    const nowDate = dateUtils.getDateForTimezone(timezone);
                    return dayjs(val).isAfter(nowDate) && !notDisableDate;
                }
            }
        };
    },

    methods: {
        handleChange(val) {
            this.$emit('change', val);
        }
    },

    watch: {
        value(val) {
            this.currValue = val;
        }
    }
};
</script>
