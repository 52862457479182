<template>
    <div class="dobule-date">
        <el-date-picker
            class="audience-item__timeRange-picker"
            type="daterange"
            format="yyyy/MM/dd"
            value-format="yyyy/MM/dd"
            :start-placeholder="$t('common.start_date')"
            :end-placeholder="$t('common.end_date')"
            :clearable="false"
            :editable="false"
            :picker-options="pickerOptions"
            v-model="currValue"
            @change="handleChange"
        ></el-date-picker>
    </div>
</template>

<script>
import dayjs from 'dayjs';
import dateUtils from '@/common/utils/date.utils';

export default {
    name: 'dobuleDateItem',

    props: {
        value: {
            required: true
        },
        filterItem: {
            type: Object
        },
        maxDayLimit: Number
    },

    data() {
        const startTime = dayjs()
            .subtract(this.maxDayLimit || 60, 'days')
            .toDate();
        const timezone = pt.global.timezone;
        const nowDate = dateUtils.getDateForTimezone(timezone);
        const notDisableDate = this.filterItem && this.filterItem.notDisableDate;
        return {
            currValue: this.value,
            pickerOptions: {
                disabledDate(val) {
                    return (dayjs(val).isAfter(nowDate) || dayjs(val).isBefore(startTime)) && !notDisableDate;
                }
            }
        };
    },
    watch: {
        value(val) {
            this.currValue = val;
        }
    },
    methods: {
        handleChange(val) {
            this.$emit('change', val);
        }
    }
};
</script>
