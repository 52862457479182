<template>
<div>
    <el-select
        filterable
        placement="bottom"
        :class="['filter-item__select', $style.engage]"
        popper-class="filter-item__popper"
        :popper-append-to-body="false"
        :size="size"
        :value="selectedEngage"
        :placeholder="$t('placeholder.campaign')"
        valueKey="value"
        labelKey="label"
        @change="handleEngageChange"
    >
        <el-option-group
            v-for="group in engageOptions"
            :key="group.label"
            :label="$t(STATUS_i18n[group.label])"
        >
            <el-option
                v-for="engage in group.options"
                :key="engage.value"
                :value="engage.value"
                :label="engage.label"
            ></el-option>
        </el-option-group>
    </el-select>
    <pt-multi-select
        filterable
        :size="size"
        :value="selectedVersion"
        :remote="remote"
        :placeholder="$t('placeholder.version')"
        :tag-placeholder="
            currFilter && ['![()]', '!='].includes(currFilter.condition) ? $t('common.and_lowercase') : $t('common.or')
        "
        :loading="remoteLoading"
        :remote-method="remoteMethod"
        :options="versionOptions"
        valueKey="value"
        labelKey="label"
        :blur-create="true"
        :trim-space="needTrimSpace"
        :allow-empty="false"
        @change="handleVersionChange"
    >
    </pt-multi-select>
</div>
</template>

<script>
import { groupBy } from 'lodash-es';
import { formatVersionLabel } from '@/components/users/usergrp-util';
export default {
    name: 'campaignItem',
    props: ['remoteMethod', 'needTrimSpace', 'currFilter', 'value', 'size', 'options', 'valueKey', 'remote', 'currFilter', 'remoteLoading'],
    data() {
        return {
            selectedEngage: '', // 单选
            selectedVersion: [], // 多选
            STATUS_PRIORITY: {
                'RUNNING': 0,
                'SCHEDULED': 1,
                'PAUSE': 2,
                'DRAFT': 3,
                'DELETED': 4
            },
            STATUS_i18n: {
                DRAFT: 'campaign_report.status_draft',
                PAUSE: 'campaign_report.status_paused',
                RUNNING: 'campaign_report.status_running',
                SCHEDULED: 'campaign_report.status_scheduled',
                DELETED: 'campaign_report.status_deleted',
            }
        }
    },
    mounted() {
        if(this.value && Array.isArray(this.value)) {
            this.splitValue(this.value)
        }
    },
    computed: {
        engageOptions() {
            const groups = groupBy(this.options, 'status');
            const groupOptions = Object.keys(groups).sort((a, b) => this.STATUS_PRIORITY[a] - this.STATUS_PRIORITY[b]).map((group) => {
                return {
                    label: group,
                    options: groups[group].map((engage) => ({ value:engage.engageId, label: engage.engageName }))
                }
            })
            return groupOptions;
        },
        versionOptions() {
            if(this.selectedEngage) {
               const targetEngage =  this.options.find((engage) => engage.engageId === this.selectedEngage);
                return targetEngage?.versions?.map((version) => ({ label: formatVersionLabel(version, this.$t('placeholder.deleted_version')), value: version.versionId })) || [];
            };
            return []
        }
    },
    methods: {
        handleVersionChange(versions) {
            this.selectedVersion = versions.map((version) => version.value);
            const value = versions.map((version) => `${this.selectedEngage}&${version.value}`);
            this.$emit('change', value)
        },
        handleEngageChange(engage) {
            // 选择版本之后需要调用接口获取下方的数
            this.selectedEngage = engage;
            this.selectedVersion = [];
        },
        splitValue(value) {
            let selectedEngage = '';
            let version = [];
            value.forEach((info) => {
                const [engageId = null, versionId = ''] = info.split('&');
                if(selectedEngage !== engageId) {
                    selectedEngage = engageId;
                }
                if(versionId) {
                    version.push(versionId);
                }
            })
            this.selectedEngage = selectedEngage;
            this.selectedVersion = version;
        }
    },
    watch: {
        value(newValue) {
           this.splitValue(newValue)
        }
    }
}
</script>

<style lang="scss" module>
.engage {
    margin-bottom: 8px;
}
</style>


