<template>
    <el-input :size="size" v-model="currValue" @change="handleChange"></el-input>
</template>

<script>
export default {
    name: 'inputItem',

    props: {
        size: {
            type: String,
            default: 'small'
        },
        value: {
            required: true
        }
    },

    data() {
        return {
            currValue: this.value || ''
        };
    },

    methods: {
        handleChange(val) {
            this.$emit('change', val);
        }
    },

    watch: {
        value(val) {
            this.currValue = val;
        }
    }
};
</script>
