<template>
    <div class="dobule-number">
        <pt-input-number :size="size" v-model="currValue[0]" @change="handleChange"></pt-input-number>
        <span>-</span>
        <pt-input-number :size="size" v-model="currValue[1]" @change="handleChange"></pt-input-number>
    </div>
</template>

<script>
export default {
    name: 'inputDobuleNumberItem',

    props: {
        size: {
            type: String,
            default: 'large'
        },
        value: {
            type: Array
        },
        min: Number
    },

    data() {
        return {
            currValue: this.value || ['', '']
        };
    },

    methods: {
        handleChange(val) {
            this.$emit('change', this.currValue);
        }
    },

    watch: {
        value(val) {
            this.currValue = val;
        }
    }
};
</script>

<style lang="scss">
.dobule-number {
    display: flex;
    align-items: center;

    span {
        margin: 0 10px;
    }
}
</style>