<template>
    <el-select
        v-if="selectHasGroup"
        filterable
        placement="bottom"
        class="filter-item__select"
        popper-class="filter-item__popper"
        :popper-append-to-body="false"
        :size="size"
        :value-key="valueKey"
        :allow-create="allowCreate"
        :value="currValue"
        :placeholder="placeholder"
        @change="handleChange"
    >
        <template v-if="selectHasGroup">
            <el-option-group
                v-for="(group, groupIndex) in options"
                :key="groupIndex"
                :label="currentIsCustomValue ? group.label : $t(group.label)"
            >
                <el-option
                    v-for="(item, itemIndex) in group.options"
                    :key="itemIndex"
                    :label="currentIsCustomValue ? item.name : $t(item.name)"
                    :title="currentIsCustomValue ? item.name : $t(item.name)"
                    :value="item[valueKey]"
                >
                    <span>{{ currentIsCustomValue ? item.name : $t(item.name) }}</span>
                    <pt-icon icon="upgrade" v-if="showUpgradeIcon(item)"></pt-icon>
                </el-option>
            </el-option-group>
        </template>

        <template v-else>
            <el-option
                v-for="item in options"
                :key="item[valueKey]"
                :label="currentIsCustomValue ? item.name : $t(item.name)"
                :value="item[valueKey]"
            ></el-option>
        </template>
    </el-select>

    <el-select
        v-else
        class="filter-item__select_event"
        :size="size"
        :value="currValue"
        :value-key="valueKey"
        :placeholder="placeholder"
        :loading="remoteLoading"
        :lineHeight="28"
        filterable
        :popper-append-to-body="false"
        @change="handleChange"
    >
        <el-option
            v-for="item in options"
            :key="item[valueKey]"
            :label="currentIsCustomValue ? item.name : $t(item.name)"
            :value="item[valueKey]"
        >
            <template v-if="cName && cName === 'subType'">
                <span :title="currentIsCustomValue ? item.name : $t(item.name)">{{
                    currentIsCustomValue ? item.name : $t(item.name)
                }}</span>
                <el-tooltip placement="top" :enterable="false" class="pt-ml4">
                    <div slot="content" v-html="$t(item.tip)"></div>
                    <pt-icon
                        icon="pt-icon--help-new"
                        :icon-style="{
                            width: '16px',
                            height: '16px',
                            fill: '#344563',
                            'margin-left': '4px',
                            'vertical-align': 'text-bottom'
                        }"
                    ></pt-icon>
                </el-tooltip>
            </template>
        </el-option>
    </el-select>
</template>

<script>
export default {
    name: 'selectItem',

    props: {
        size: {
            type: String,
            default: 'small'
        },
        cName: {
            type: String,
            default: ''
        },
        value: {
            required: true
        },
        valueKey: {
            type: String,
            default: 'code'
        },
        options: Array,
        placeholder: String,
        allowCreate: {
            type: Boolean,
            default: false
        },
        remoteLoading: Boolean,
        currFilter: {
            type: Object
        },
        filterItem: {
            type: Object
        }
    },

    data() {
        return {
            currValue: this.value
        };
    },
    computed: {
        selectHasGroup() {
            return (this.options || []).some(item => item.hasOwnProperty('label'));
        },
        currentIsCustomValue() {
            // 用户自定义数据下拉 不进行翻译直接显示该值即可（比如事件名下拉框）
            return this.filterItem?.key === 'eventName';
        }
    },

    inject: ['root'],

    methods: {
        handleChange(val) {
            this.$emit('change', val);
        },
        showUpgradeIcon(option) {
            if (option.authType) {
                if (option.authType === 'audienceOngoingBehavior') {
                    return this.root.cannotCreateOngoing;
                }
            }
            return false;
        }
    },

    watch: {
        value(newVal) {
            this.currValue = newVal;
        }
    }
};
</script>

<style lang="scss">
.filter-item__select {
    .el-select-dropdown {
        left: 5px !important;
        right: 0 !important;
    }
}
.filter-item__popper {
    margin: 1px 0 0 -5px !important;
    box-shadow: 0 2px 6px 0 #c0c4cc;
    border: 1px solid #ccc;

    .popper__arrow {
        display: none;
    }
}
.filter-item__select_event {
    .el-select-dropdown {
        width: 100%;
        left: 0 !important;
    }
}
</style>
