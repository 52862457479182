<template>
    <div class="filter-item__popover" v-pt-clickoutside="handleClickOutside">
        <div class="filter-item__title" :class="{ focus: visiblePopver }" @click="togglePopver(!visiblePopver)">
            {{ timeRangeText }}
            <span class="filter-item__title-suffix">
                <i class="el-icon-arrow-down"></i>
            </span>
        </div>
        <transition name="el-fade-in-linear" v-if="visiblePopver">
            <ul class="el-dropdown-menu el-popper filter-item__popover-container">
                <li
                    class="el-dropdown-menu__item"
                    :class="{ activeOption: item.code === currValue['timeRange'] }"
                    v-for="item in options"
                    :key="item.code"
                    @click="handleChange(item)"
                >
                    <span>{{
                        $t(item.name, { date: item.code === 'since' ? $t('dimension.specific_date') : '' })
                    }}</span>
                    <pt-icon icon="upgrade" v-if="showUpgradeIcon(item)"></pt-icon>
                    <div v-if="currValue['timeRange'] === item.code">
                        <pt-input-number
                            v-if="item.code === 'lastDays'"
                            v-model="currValue['timeDays']"
                            size="large"
                            :min="0"
                            :max="maxQueryDayLimit"
                            @change="handleLastdaysChange"
                        ></pt-input-number>
                        <div v-if="item.code === 'lastDays'">
                            <el-checkbox
                                @change="
                                    handleLastdaysChange({ type: 'timeWithToday', value: currValue['timeWithToday'] })
                                "
                                v-model="currValue['timeWithToday']"
                                >{{ $t('dimension.include_today') }}</el-checkbox
                            >
                        </div>

                        <div class="filter-item__timeRange" v-if="['since', 'on', 'between'].includes(item.code)">
                            <el-date-picker
                                class="filter-item__timeRange-picker"
                                type="date"
                                format="yyyy/MM/dd"
                                value-format="yyyy/MM/dd"
                                :clearable="false"
                                :editable="false"
                                :picker-options="pickerOptions"
                                v-model="currValue.timeFrom"
                                @change="handleLastdaysChange({ type: 'timeFrom', value: currValue.timeFrom })"
                            ></el-date-picker>
                            <el-date-picker
                                v-if="item.code === 'between'"
                                type="date"
                                class="filter-item__timeRange-picker"
                                format="yyyy/MM/dd"
                                value-format="yyyy/MM/dd"
                                :picker-options="pickerOptions"
                                :clearable="false"
                                :editable="false"
                                v-model="currValue.timeTo"
                                @change="handleLastdaysChange({ type: 'timeTo', value: currValue.timeTo })"
                            ></el-date-picker>
                        </div>
                    </div>
                </li>
            </ul>
        </transition>
    </div>
</template>

<script>
import dayjs from 'dayjs';
import dateUtils from '@/common/utils/date.utils';

export default {
    name: 'timeRangeItem',

    props: {
        value: {
            required: true
        },
        options: Array,
        maxDayLimit: Number
    },

    inject: ['root'],

    data() {
        return {
            currValue: this.value || '',
            maxQueryDayLimit: this.maxDayLimit || 60,
            visiblePopver: false,
            rangeTypeMap: {
                lastDays: 'timeDays',
                since: 'timeFrom',
                on: 'timeFrom',
                between: ['timeFrom', 'timeTo']
            }
        };
    },
    computed: {
        defaultTimeRange() {
            if (this.currValue.type === 'visitWebsite') {
                return this.options.find(item => item.code === 'lastDays');
            } else return this.options.find(item => item.defaultValue);
        },
        pickerOptions() {
            const startTime = dayjs()
                .subtract(this.maxQueryDayLimit, 'days')
                .toDate();
            const timezone = pt.global.timezone;
            const nowDate = dateUtils.getDateForTimezone(timezone);
            return {
                disabledDate(val) {
                    return dayjs(val).isAfter(nowDate) || dayjs(val).isBefore(startTime);
                },
                firstDayOfWeek: 1
            };
        },
        timeRangeText() {
            const timeRangeName =
                (this.options &&
                    this.currValue.timeRange &&
                    this.options.find(item => item.code === this.currValue.timeRange) &&
                    this.options.find(item => item.code === this.currValue.timeRange).name) ||
                this.defaultTimeRange.name;
            const timeDays = this.currValue.timeDays || '0';
            const timeFrom = this.currValue.timeFrom || '';
            const timeTo = this.currValue.timeTo || '';
            if (this.currValue.timeRange === 'lastDays') {
                return (
                    this.$t(timeRangeName).split('...')[0] +
                    ' ' +
                    timeDays +
                    ' ' +
                    this.$t(timeRangeName).split('...')[1]
                );
            } else if (['on', 'since'].includes(this.currValue.timeRange)) {
                return this.$t(timeRangeName, { date: timeFrom });
            } else if (this.currValue.timeRange === 'between') {
                return timeFrom + ' - ' + timeTo;
            } else {
                return this.$t(timeRangeName);
            }
        }
    },

    methods: {
        handleChange(val) {
            this.$set(this.currValue, 'timeRange', val.code);
            this.$emit('change', {
                type: 'timeRange',
                value: val.code,
                authType: val.authType
            });
        },
        handleLastdaysChange(val) {
            const timeRangeType = this.rangeTypeMap[this.currValue.timeRange];
            if (this.currValue.timeRange !== 'lastDays') {
                this.$emit('change', val);
            } else if (val.type && val.type === 'timeWithToday') {
                this.$emit('change', val);
            } else {
                this.$emit('change', { type: timeRangeType, value: val });
            }
        },
        showUpgradeIcon(option) {
            if (option.authType) {
                if (option.authType === 'audienceOngoingBehavior') {
                    return this.root.cannotCreateOngoing;
                }
            }
            return false;
        },

        handleClickOutside() {
            this.togglePopver(false);
        },

        togglePopver(status) {
            this.visiblePopver = status;
        }
    },

    watch: {
        value(val) {
            this.currValue = val;
        }
    }
};
</script>
