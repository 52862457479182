<template>
    <el-autocomplete
        v-model="currValue"
        clearable
        :size="size"
        :popper-append-to-body="false"
        :value-key="valueKey"
        :placeholder="placeholder"
        :fetch-suggestions="querySearch"
        @input="notice"
        @select="handleSelect"
    ></el-autocomplete>
</template>

<script>
export default {
    name: 'autocompleteItem',

    props: {
        size: {
            type: String,
            default: 'small'
        },
        value: String,
        options: Array,
        valueKey: {
            type: String,
            default: 'code'
        },
        placeholder: String
    },

    data() {
        return {
            currValue: this.value
        };
    },

    methods: {
        querySearch(queryString, cb) {
            var options = this.options;
            var results = queryString ? options.filter(this.createFilter(queryString)) : options;
            // 调用 callback 返回建议列表的数据
            cb(results);
        },

        createFilter(queryString) {
            return option => {
                return option[this.valueKey].toLowerCase().indexOf(queryString.toLowerCase()) === 0;
            };
        },

        handleSelect(val) {
            this.notice(val[this.valueKey]);
        },

        notice(val) {
            this.$emit('change', val);
        }
    },

    watch: {
        value(val) {
            this.currValue = val;
        }
    }
};
</script>