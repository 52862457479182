import i18n from '@assets/i18n';

export default {
    // startToDisplay条件中 页面到达率下拉列表
    scrollMatchOptions: [
        { value: '0%', text: i18n.t('engagement.setting_engage_first_view') },
        { value: '10%', text: '10%' },
        { value: '20%', text: '20%' },
        { value: '30%', text: '30%' },
        { value: '40%', text: '40%' },
        { value: '50%', text: '50%' },
        { value: '60%', text: '60%' },
        { value: '70%', text: '70%' },
        { value: '80%', text: '80%' },
        { value: '90%', text: '90%' },
        { value: '100%', text: i18n.t('engagement.setting_engage_the_end') }
    ],
    // displayAt条件中 匹配模式下拉列表
    entryPatternList: [
        { value: '[()]', text: i18n.t('common.include') },
        { value: '![()]', text: i18n.t('common.exclude'), hide: true },
        { value: '[(', text: i18n.t('common.head_match') },
        { value: ')]', text: i18n.t('common.end_match') },
        { value: '==', text: i18n.t('common.exact_match') },
        { value: 'Rex', text: i18n.t('common.regex'), hide: true }
        // {'value':"!*",'text':i18n.t('common.empty')}
    ],
    config: {
        start: [
            {
                type: 'UserBlur',
                value: true,
                condition: '=='
            }, // 用户失去焦点
            {
                type: 'UserStay',
                value: '', //用户停留时间 单位s
                condition: '>='
            },
            {
                type: 'UserStop',
                value: '', //用户停止活动时间 单位s
                condition: '>='
            },
            {
                type: 'ScrollMatch',
                value: '0%',
                condition: '>='
            }
        ],
        stop: [
            {
                type: 'SeenTimes',
                value: 1,
                condition: '>'
            },
            {
                type: 'ClickButton',
                value: true,
                condition: '=='
            },
            {
                type: 'CloseTimes',
                value: 1,
                condition: '>'
            },
            {
                type: 'FormSubmitted',
                value: 0,
                condition: '>='
            }
        ],
        schedule: [
            {
                type: 'Dater',
                value: [1555430400, 1558195199], // 存放form和to 时间戳 to可为空
                condition: ''
            },
            {
                type: 'Week',
                value: [0, 1, 2, 3, 4, 5, 6], // 0代表sunday 1代表monday 依次类推
                condition: ''
            },
            {
                type: 'Time',
                value: ['09:00', '18:00'],
                condition: ''
            }
        ],
        when: [
            {
                type: 'URL', // 两种值 URL(默认) URLGROUP
                condition: '[()]', //条件值 等于 不等于 包含 头匹配 尾匹配
                value: ''
            }
        ],
        timezone: {
            profile: '+8:00',
            useVisitor: true // 是否使用访客时区
        }
    },
    originalPatternList: ['[()]', '![()]', '[(', 'Rex'], // 没有合参概念的条件
    pageTypeList: [
        {
            code: 'page',
            name: i18n.t('dimension.include_params'),
            tip: i18n.t('dimension.include_params_tip')
        },
        {
            code: 'combinedPage',
            name: i18n.t('dimension.exclude_params'),
            tip: i18n.t('dimension.exclude_params_tip')
        }
    ]
};
